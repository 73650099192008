<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CCardHeader>
                <strong
                  >Details of {{ user.first_name }} {{ user.last_name }}
                </strong>
              </CCardHeader>

              <CCardBody>
                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="firstName"
                      label="First Name"
                      placeholder="Enter the first name of the user"
                      v-model="user.first_name"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CInput
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter the last name of the user"
                      v-model="user.last_name"
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol sm="6">
                    <CInput
                      name="email"
                      label="Email"
                      placeholder="Enter the email of the user"
                      v-model="user.email"
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      placeholder="Select"
                      label="Location"
                      :options="locations"
                      :value.sync="user.location_id"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      value="false"
                      v-model="user.location_id"
                    />
                  </CCol>
                  <CCol sm="6">
                    <CSelect
                      v-if="$store.getters.isAdmin"
                      label="Type"
                      :options="[
                        { label: 'Admin', value: 'admin' },
                        { label: 'Location Elite', value: 'location_elite' },
                        { label: 'Location Admin', value: 'location_admin' },
                        {
                          label: 'Location Regular',
                          value: 'location_regular',
                        },
                      ]"
                      :value.sync="user.type"
                    />
                    <CInput
                      v-if="!$store.getters.isAdmin"
                      type="hidden"
                      value="location_regular"
                      v-model="user.type"
                    />
                  </CCol>
                </CRow>
                <!-- <CRow>
                      <CCol sm="6">
                        <CInput
                              label="Password"
                              placeholder="Enter password"
                              description="Password must be at least 6 characters"
                              type="password"
                              v-model="user.password"
                      />
                      </CCol>
                      <CCol sm="6">
                        <CInput
                                label="Password"
                                placeholder="Repeat password"
                                type="password"
                                v-model="user.password_confirmation"
                        />
                      </CCol>
                    </CRow> -->

                <CCardFooter>
                  <CButton
                    variant="outline"
                    type="submit"
                    color="primary"
                    @click="updateUser"
                  >
                    <CIcon name="cil-check-circle" /> Update
                  </CButton>
                  <CButton
                    class="float-right"
                    variant="outline"
                    type="submit"
                    color="danger"
                    @click="deleteUser"
                  >
                    <CIcon name="cil-trash" /> Delete
                  </CButton>
                </CCardFooter>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import UserAPI from "/app/src/api/user.js";
import LocationAPI from "/app/src/api/location.js";

export default {
  name: "Detail",
  data: () => {
    return {
      user: {},
      userAPI: new UserAPI(),
      locationAPI: new LocationAPI(),
      locations: [],
      types: ["admin", "location_admin", "location"],
    };
  },
  created: function () {
    this.getUser(this.$route.params.id);
    this.getLocations();
  },
  methods: {
    getUser: function (id) {
      this.$store.dispatch("loading");
      let self = this;
      self.userAPI
        .detail(id)
        .then((user) => {
          this.$store.dispatch("stopLoading");
          console.log(user);
          self.user = user;
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    getLocations: function () {
      this.$store.dispatch("loading");
      let self = this;
      self.locationAPI
        .all()
        .then((result) => {
          this.$store.dispatch("stopLoading");
          for (let i = 0; i < result.length; i++) {
            self.locations.push({ label: result[i].name, value: result[i].id });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("stopLoading");
          this.$alert.show({ type: "danger", message: error });
        });
    },
    updateUser: function (id) {
      console.log(this.user);
      this.$store.dispatch("loading");
      let self = this;
      this.userAPI
        .update(self.user)
        .then((result) => {
          this.$store.dispatch("stopLoading");
          this.$alert.show({
            type: "success",
            message: "User has been updated",
          });
        })
        .catch((error) => {
          this.$store.dispatch("stopLoading");
          console.log(error);
          this.$alert.show({ type: "danger", message: error });
        });
    },
    deleteUser: function (id) {
      const params = {
        title: "Confirm",
        text: "Are you sure you want to delete?",
        onConfirm: () => {
          this.$store.dispatch("loading");
          this.userAPI
            .delete(this.$route.params.id)
            .then((result) => {
              this.$store.dispatch("stopLoading");
              console.log(result);
              this.$router.push({ path: "/manager/users" });
            })
            .catch((error) => {
              this.$store.dispatch("stopLoading");
              console.log(error);
              this.$alert.show({ type: "danger", message: error });
            });
        },
      };
      this.$confirm.show(params);
    },
  },
};
</script>
