var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("Details of "+_vm._s(_vm.user.first_name)+" "+_vm._s(_vm.user.last_name)+" ")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"firstName","label":"First Name","placeholder":"Enter the first name of the user"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"lastName","label":"Last Name","placeholder":"Enter the last name of the user"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[_c('CInput',{attrs:{"name":"email","label":"Email","placeholder":"Enter the email of the user"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"6"}},[(_vm.$store.getters.isAdmin)?_c('CSelect',{attrs:{"placeholder":"Select","label":"Location","options":_vm.locations,"value":_vm.user.location_id},on:{"update:value":function($event){return _vm.$set(_vm.user, "location_id", $event)}}}):_vm._e(),(!_vm.$store.getters.isAdmin)?_c('CInput',{attrs:{"type":"hidden","value":"false"},model:{value:(_vm.user.location_id),callback:function ($$v) {_vm.$set(_vm.user, "location_id", $$v)},expression:"user.location_id"}}):_vm._e()],1),_c('CCol',{attrs:{"sm":"6"}},[(_vm.$store.getters.isAdmin)?_c('CSelect',{attrs:{"label":"Type","options":[
                      { label: 'Admin', value: 'admin' },
                      { label: 'Location Elite', value: 'location_elite' },
                      { label: 'Location Admin', value: 'location_admin' },
                      {
                        label: 'Location Regular',
                        value: 'location_regular',
                      },
                    ],"value":_vm.user.type},on:{"update:value":function($event){return _vm.$set(_vm.user, "type", $event)}}}):_vm._e(),(!_vm.$store.getters.isAdmin)?_c('CInput',{attrs:{"type":"hidden","value":"location_regular"},model:{value:(_vm.user.type),callback:function ($$v) {_vm.$set(_vm.user, "type", $$v)},expression:"user.type"}}):_vm._e()],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"variant":"outline","type":"submit","color":"primary"},on:{"click":_vm.updateUser}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" Update ")],1),_c('CButton',{staticClass:"float-right",attrs:{"variant":"outline","type":"submit","color":"danger"},on:{"click":_vm.deleteUser}},[_c('CIcon',{attrs:{"name":"cil-trash"}}),_vm._v(" Delete ")],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }